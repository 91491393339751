/* vars */
:root {
  --clr-primary: hsl(340, 55%, 53%);
  --clr-secondary: hsl(2, 80%, 69%);
  --clr-tertiary: hsl(40, 100%, 63%);
  --clr-bg: hsl(62, 79%, 72%);
  --clr-accent: hsl(73, 53%, 57%);
  --clr-blue: hsl(209, 80%, 69%);
  --clr-halfgrey: hsl(0, 0%, 50%);
  /* pinks */
  /* --clr-peony: hsl(1, 70%, 73%);
  --clr-hotpink: hsl(340, 55%, 53%); */
  /* i like your smile theme */
  /* --clr-redpink: hsl(344, 47%, 46%);
  --clr-lovepink: hsl(2, 80%, 69%);
  --clr-brorange: hsl(40, 100%, 63%);
  --clr-brlime: hsl(62, 79%, 72%);
  --clr-brgreen: hsl(73, 53%, 57%); */
}

.pink {
  color: var(--clr-secondary);
}
.blue {
  color: var(--clr-blue);
}
.black {
  color: black;
}
.grey {
  color: var(--clr-halfgrey);
}

/* common */

body {
  margin: 0;
  font-family: 'Ubuntu', 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  outline: 0;
}

small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* typography */
.name {
  font-size: .8em;
  font-weight: 700;
  padding-left: 1em;
}
.kanji {
  font-size: .8em;
  font-weight: 700;
  padding-left: 1em;
}
.kana {
  font-size: .7em;
  padding-left: 1em;
  color: var(--clr-halfgrey);
}
.parens::before {
  content: "(";
}
.parens::after {
  content: ")";
}
.horispace {
  padding-right: .5rem;
  padding-left: .5rem;
}

/* main container */
section.Gallery {
  padding: 0 .5rem;
}

/* search bar */

.search-input {
  width: calc(100vw - 150px);
  margin: .2rem 2.5rem;
  padding: .6rem .8rem;
  font-size: 1.2em;
}
.listbox {
  margin-top: 0;
}
.search-suggestion li {
  list-style-type: none;
}
button.suggestion:hover {
  background-color: var(--clr-bg);
  border-radius: 5px;
}

/* images */

.img-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: 150px;
  grid-auto-flow: dense;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: space-evenly;
}
.img-item {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 1;
  border-radius: 8px;
  box-shadow: 0 0 8px rgb(10, 10, 10);
  cursor: pointer;
  transition: all 0.5s ease;
  animation: fadein 5s;
}
.img-item:nth-child(1) { animation: fadein .5s;}
.img-item:nth-child(2) { animation: fadein 1s;}
.img-item:nth-child(3) { animation: fadein 1.5s;}
.img-item:nth-child(4) { animation: fadein 2s;}
.img-item:nth-child(5) { animation: fadein 2.5s;}
.img-item:nth-child(6) { animation: fadein 3s;}
.img-item:nth-child(7) { animation: fadein 3.5s;}
.img-item:nth-child(8) { animation: fadein 4s;}
.img-item:nth-child(9) { animation: fadein 4.5s;}
.img-item:hover {
  transform: scale(1.025);
}
.img-item.portrait {
  grid-row: span 2;
}
.img-item img {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.tag-count {
  font-size: .6em;
  position: absolute;
  color: black;
  background-color: white;
  /* text-shadow: white 0px 0px 10px; */
}

/* overlay */

.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  z-index: 2;
}
.overlay.open {
  display: grid;
  align-items: center;
  justify-items: center;
}
.overlay-inner {
  background: white;
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
  padding: 20px;
  text-align: center;
}
.overlay img {
  animation: zoomin .5s ease;
  max-width: 100%;
  max-height: calc(100vh - 200px);
  margin-top: .5rem;
  object-fit: contain;
}
/* .overlay img.portrait {
  height: calc(100vh - 250px);
  width: auto;
} */
.overlay video {
  width: 100%;
}
.overlay video.portrait {
  height: calc(100vh - 125px);
  width: auto;
}
.close-crosshair {
  color: red;
  cursor: pointer;
  /* position: absolute;
  margin-left: -18px;
  margin-top: -18px; */
}
span.block {
  font-size: 10px;
  margin-right: .2rem;
  border: 1px solid grey;
  padding: 2px;
  font-style: italic;
  color: rgb(40, 40, 40);
  border-radius: 5px;
  cursor: not-allowed;
}

/* overlay search bar */

.search-input-overlay {
  width: calc(100vw - 250px);
  margin: .2rem 2.5rem;
  padding: .6rem .8rem;
  font-size: 1.2em;
}

/* slider */
.TimeSlider {
  margin: 1rem auto;
}
.rc-slider-track {
  background-color: var(--clr-secondary) !important;
}

/* animations */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes zoomin {
  0% {
    max-width: 25%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}



/* phone */

.mobile-row { display: inline-flex; }
.overlay-header {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}
.TimeSlider {
  font-size: 1em;
}
@media only screen and (max-width: 600px) {
  .overlay-header {
    flex-direction: column;
  }
  .TimeSlider {
    font-size: .7em;
  }
  .search-input {
    width: 90%;
    margin: .2rem 0;
  }
}