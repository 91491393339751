.top {
  text-align: center;
  color: var(--clr-primary);
  /* background: url("images/anchor.svg") center no-repeat; */
  /* background-size: cover; */
}
.top-right {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.top h1 {
  font-size: 4rem;
  line-height: 0.7;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: .25rem;
}

.and {
  display: flex;
  font-size: 2rem;
  color: var(--clr-secondary);
  justify-content: center;
  align-items: center;
  padding: 0 1rem 5px 1rem;
}

.tagline {
  margin: 0;
  font-size: 2rem;
  position: relative;
  display: inline-block;
  color: var(--clr-secondary);
}
.tagline span {
  color: var(--clr-tertiary);
  background: #fff;
  position: relative;
  z-index: 2;
  padding-left: 1rem;
  padding-right: 1rem;
}
/* .tagline:before,
.tagline:after {
  display: block;
  z-index: 1;
  background: var(--clr-secondary);
  position: absolute;
  width: 130%;
  height: 1px;
  content: '';
  top: 12px;
  margin-left: -15%;
}
.tagline:after {
  top: auto;
  bottom: 7px;
} */